<template>
  <div class="app flex-row align-items-center not-found-page">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">{{$t('No encontramos la página que buscas.')}}</h4>
            <p class="text-muted">{{$t('Puedes intentar con algunas de estas secciones:')}}</p>
          </div>
          <div class="input-prepend input-group">
            <router-link v-for="link in links" :key="link.value" :to="link.value">
              <b-button variant="info">{{$t(link.text)}}</b-button>
            </router-link>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: 'Page404',
  data () {
    return {
      links: [
        { text: 'Pedidos', value: '/orders/list' },
        { text: 'Productos', value: '/catalog/list' },
        { text: 'Inventario', value: '/inventory/list' },
        { text: 'Transportadoras', value: '/carriers/carrierList' },
        { text: 'Precios', value: '/carriers/priceList' },
        { text: 'Reportes', value: '/reports/list' }
      ]
    }
  }
}
</script>
