let checkPermissions = (permissions, checkTo) => permissions.includes(checkTo)

import appConfig from '@/config/applicationConfig.js'

const VentasItems = (roles) => {
  const ORDER_LIST_ITEM = {
    name: 'Ordenes',
    url: '/orders/list',
    icon: 'fa fa-shopping-bag'
  }
  const ORDER_CSV_PUNISHMNETS_ITEM = {
    name: 'Penalizaciones CSV',
    url: '/orders/addPunishmentCSV',
    icon: 'fa fa-list-alt'
  }
  const ORDER_CSV_LIQUIDATIONS_ITEM = {
    name: 'Liquidaciones CSV',
    url: '/orders/addLiquidationsCSV',
    icon: 'fa fa-list-alt'
  }
  const TAXES_ITEM = {
    name: 'Impuestos',
    url: '/orders/taxes',
    icon: 'fa fa-list-alt'
  }
  const SUBSTATUS = {
    name: 'Sub-estados',
    url: '/orders/substatus',
    icon: 'fa fa-list-alt'
  }

  let result = []
  if (checkPermissions(roles, 'ORDER_WRITE') && !appConfig.HIDE_PENALIZACIONES_LIQUIDACIONES) {
    result.push(ORDER_CSV_PUNISHMNETS_ITEM)
    result.push(ORDER_CSV_LIQUIDATIONS_ITEM)
  }
  if (checkPermissions(roles, 'ORDER_READ') && (appConfig.HIDE_IMPUESTOS === 'false' || appConfig.HIDE_IMPUESTOS === false)) {
    result.push(TAXES_ITEM)
  }

  if (checkPermissions(roles, 'ORDER_READ')) {
    result.push(ORDER_LIST_ITEM)
    result.push(SUBSTATUS)
  }
  return result
}

const CatalogItems = (roles, isAdmin) => {
  const PRODUCT_MANAGEMENT_ITEM = {
    name: 'Administrar Productos',
    url: '/catalog/list',
    icon: 'fa fa-list-alt'
  }
  const PRODUCT_CREATION_ITEM = {
    name: 'Alta manual de Productos',
    url: '/catalog/new',
    icon: 'fa fa-list-alt'
  }
  const MASIVE_PRODUCT_CREATION_ITEM = {
    name: 'Alta masiva de Productos',
    url: '/catalog/addProductsCSV',
    icon: 'fa fa-list-alt'
  }
  const MASIVE_UPDATE_PRICES_ITEM = {
    name: 'Actualización masiva de Precios',
    url: '/catalog/changePricesCSV',
    icon: 'fa fa-list-alt'
  }
  const INVENTORY_LIST_ITEM = {
    name: 'Administrar Inventario',
    url: '/catalog/inventory/list',
    icon: 'fa fa-list-alt'
  }
  const PROMOTIONS = {
    name: 'Promociones',
    url: '/catalog/promotions',
    icon: 'fa fa-tags'
  }

  let response = []
  
  if (checkPermissions(roles, 'PRODUCT_READ')) {
    response = response.concat([PRODUCT_MANAGEMENT_ITEM])
  }
  if (checkPermissions(roles, 'PRODUCT_WRITE') && !isAdmin) {
    response = response.concat([PRODUCT_CREATION_ITEM])
  }
  if (checkPermissions(roles, 'PRODUCT_READ') && !isAdmin) {
    response = response.concat([MASIVE_PRODUCT_CREATION_ITEM])
  }
  if (checkPermissions(roles, 'PRICE_READ')) {
    response = response.concat([MASIVE_UPDATE_PRICES_ITEM])
  }
  if (checkPermissions(roles, 'INVENTORY_READ')) {
    response = response.concat([INVENTORY_LIST_ITEM])
  }
  if (checkPermissions(roles, 'PROMOTION_READ')) {
    response = response.concat([PROMOTIONS])
  }
  return response
}

const AdminItems = (roles, isAdmin) => {
  const USER_LIST_ITEM = {
    name: 'Usuarios',
    url: '/admin/users',
    icon: 'fa fa-list-alt'
  }
  const SELLER_LIST_ITEM = {
    name: 'Sellers',
    url: '/admin/sellers',
    icon: 'fa fa-list-alt'
  }
  const INTEGRATIONS_ITEM = {
    name: 'Integraciones',
    url: '/admin/integrations',
    icon: 'fa fa-list-alt'
  }
  const ROLES_ITEM = {
    name: 'Roles',
    url: '/admin/roles',
    icon: 'fa fa-list-alt'
  }
  const NOTICES_ITEM = {
    name: 'Notificaciones',
    url: '/admin/notices',
    icon: 'fa fa-list-alt'
  }
  let response = []
  if (checkPermissions(roles, 'ADMIN_READ')) {
    response = response.concat([USER_LIST_ITEM])
    if (isAdmin) {
      response = response.concat([SELLER_LIST_ITEM, ROLES_ITEM, NOTICES_ITEM])
      if (appConfig.HIDE_INTEGRACIONES === 'false') {
        response = response.concat([INTEGRATIONS_ITEM])
      }
    }
  }

  return response
}

const CarrierItems = roles => {
  const CARRIER_LIST_ITEM = {
    name: 'Transportadoras',
    url: '/carriers/carrierList',
    icon: 'fa fa-list-alt'
  }
  const PRICE_LIST_ITEM = {
    name: 'Precios',
    url: '/carriers/priceList',
    icon: 'fa fa-list-alt'
  }
  const MASIVE_PRICE_LIST_ITEM = {
    name: 'Alta masiva de Precios de Transportadoras',
    url: '/carriers/addPricesCSV',
    icon: 'fa fa-list-alt'
  }
  const PICKUP_POINTS = {
    name: 'Puntos de retiro',
    url: '/carriers/pickupPoints',
    icon: 'fa fa-list-alt'
  }
  let paths = [PICKUP_POINTS]
  if (checkPermissions(roles, 'CARRIER_READ')) {
    paths = paths.concat([CARRIER_LIST_ITEM, PRICE_LIST_ITEM, MASIVE_PRICE_LIST_ITEM])
  }
  return paths
}

const UpdateItems = roles => {
  const BRAND_CATEOGIES_ITEM = {
    name: 'Catalogo y Marcas',
    url: '/updates/catalogAndBrand',
    icon: 'fa fa-list-alt'
  }
  const ATTRIBUTES_ITEM = {
    name: 'Atributos',
    url: '/updates/attributes',
    icon: 'fa fa-list-alt'
  }
  let paths = []
  if (checkPermissions(roles, 'UPDATE_WRITE')) {
    paths = paths.concat([BRAND_CATEOGIES_ITEM, ATTRIBUTES_ITEM])
  }
  return paths
}

export default {
  items: (roles, isAdmin) => {
    const DASHBOARD_NAV = {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fa fa-tachometer-alt'
    }
    const ORDERS_NAV = {
      name: 'Ventas',
      url: '/orders/',
      icon: 'fa fa-shopping-bag',
      children: VentasItems(roles)
    }
    const CATALOG_NAV = {
      name: 'Catálogo',
      url: '/catalog',
      icon: 'fa fa-list-alt',
      children: CatalogItems(roles, isAdmin)
    }
    const REPORTS_NAV = {
      name: 'Reportes',
      url: '/reports',
      icon: 'fa fa-list-alt'
    }
    const INCIDENTS_NAV = {
      name: 'Incidencias',
      url: '/incidents',
      icon: 'fa fa-bug'
    }
    const USERS_NAV = {
      name: 'Administración',
      url: '/admin',
      icon: 'fa fa-list-alt',
      children: AdminItems(roles, isAdmin)
    }
    const CARRIERS_NAV = {
      name: 'Gestión de envíos',
      url: '/carriers',
      icon: 'fa fa-list-alt',
      children: CarrierItems(roles)
    }
    const UPDATES = {
      name: 'Actualizaciónes',
      url: '/updates',
      icon: 'fa fa-list-alt',
      children: UpdateItems(roles)
    }

    let result = [DASHBOARD_NAV]
    if (ORDERS_NAV.children.length > 0) result.push(ORDERS_NAV)
    if (CATALOG_NAV.children.length > 0) result.push(CATALOG_NAV)
    if (CARRIERS_NAV.children.length > 0) result.push(CARRIERS_NAV)
    if (checkPermissions(roles, 'REPORT_READ')) result.push(REPORTS_NAV)
    if (checkPermissions(roles, 'INCIDENCE_READ')) result.push(INCIDENTS_NAV)
    if (USERS_NAV.children.length > 0) result.push(USERS_NAV)
    if (UPDATES.children.length > 0) result.push(UPDATES)

    return result
  }
}
