import appConfig from '@/config/applicationConfig.js'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import { ValidationsMixim } from '@/mixims/ValidationsMixim.js'
import {SubStatusMixim} from '@/mixims/SubStatusMixim.js'

export default {
    name: 'substatus-list',
    mixins: [SubStatusMixim, ValidationsMixim],
    components: {MoonLoader},
    data () {
        return {
          fields: [
              { key: 'status', label: 'Estado', 'class': 'text-left', sortable: true },
              { key: 'name', label: 'Sub Estado', 'class': 'text-left', sortable: true },
              { key: 'blocking', label: 'Bloqueante', 'class': 'text-left', sortable: true },
              { key: 'acciones', label: 'Acciones', 'class': 'text-left' },
          ],
          isAdmin: this.$store.getters.isAdmin,
          currentPage: 1,
          perPage: 10,
          totalRows: 0,
          pageOptions: [ 10, 15, 25, 50, 100 ],
          sortBy: null,
          sortDesc: false,
          filter: null,
          errorText: '',
          successText: '',
          showFilters: false,
          loading: false,
          locale: appConfig.LOCALE,
          config: {
              format: 'YYYY-MM-DD',
              useCurrent: false,
              icons: {
              previous: 'fa fa-chevron-left',
              next: 'fa fa-chevron-right'
          }
          },
          substatusName: null,
          statusMapping: appConfig.STATUS_MAPPING,
          filterStatusMapping: appConfig.FILTER_STATUS_MAPPING,
          form: {
              status: null,
              substatus: '',
              blocking: false,
          },
          formEdit: {
            id: null,
            status: null,
            name: null,
            blocking: false,
            isEditable: false,
          },
          subStateDelete: {
            id: null,
            name: null,
            canDelete: false,
          },
        }
    },
    computed: {
      disableFilter () {
        return !this.isValidText(this.filter)
      },
      validateInputEdit () {
          return !this.isValidText(this.formEdit.name, true)
      },
      validateInput () {
        return !this.isValidText(this.form.substatus, true) || !this.isValidText(this.form.status, true)
      },
      sortOptions () {
          return this.fields
              .filter(f => f.sortable)
              .map(f => { return { text: f.label, value: f.key } })
          },
    },
    methods: {
        onFiltered (filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        cleanFilter () {
          this.filter = null
          this.$refs.subStatusTable.refresh()
        },
        filterTable () {
            this.$refs.subStatusTable.refresh()
        },
        showModalEdit (itm) {
          this.getOrdersSubstatus(itm.id).then(function (response) {
            itm.isEditable = response.body.length == 0
            this.formEdit = itm 
          },
          (error) => {
              this.loading = false
              this.errorText = error.body.message || this.$t('Error editando sub-estado')
          })
          this.$refs['modalEditStatus'].show()
        },
        showModalDelete (itm) {
          this.getOrdersSubstatus(itm.id).then(function (response) {
            this.subStateDelete.id = itm.id
            this.subStateDelete.name = itm.name
            this.subStateDelete.canDelete = response.body.length == 0
          },
          (error) => {
              this.loading = false
              this.errorText = error.body.message || this.$t('Error borrando sub-estado')
          })
          this.$refs['deleteSubStatus'].show()
        },
        closeModalDelete () {
          this.$refs['deleteSubStatus'].hide()
        },
        closeModalEdit () {
          this.$refs['modalEditStatus'].hide()
        },
        closeModalAdd () {
          this.$refs['modal-addNewSubStatus'].hide()
        },
        saveSubStatus () {
          let status = {
              "name": this.form.substatus,
              "status": this.form.status,
              "blocking": this.form.blocking,
          }
          this.loading = true
          this.errorText = ''
          return this.saveStatus(status).then(function (response) {
              this.$refs.subStatusTable.refresh()
              this.loading = false
              this.successText = 'Sub-estado agregado con éxito'
              this.form.status = null
              this.form.substatus = ''
              this.closeModalAdd()
          },
          (error) => {
              this.loading = false
              this.errorText = error.body.message || this.$t('Error guardando sub estado')
              return []
          })
        },
        editSubstate () {
          let status = {
            "id": this.formEdit.id,
            "name": this.formEdit.name,
            "status": this.formEdit.status,
            "blocking": this.formEdit.blocking,
          }
          this.loading = true
          this.errorText = ''
          return this.editSubStatus(status).then(function (response) {
              this.$refs.subStatusTable.refresh()
              this.loading = false
              this.closeModalEdit()
              this.successText = 'Sub-estado editado con éxito'
          },
          (error) => {
              this.loading = false
              this.errorText = error.body.message || this.$t('Error editando sub-estado')
              return []
          })
        },
        deleteSubStatus () {
          this.loading = true
          this.errorText = ''
          return this.deleteSubState(this.subStateDelete.id).then(function (response) {
              this.$refs.subStatusTable.refresh()
              this.loading = false
              this.closeModalDelete()
              this.successText = 'Sub-estado eliminado con éxito'
          },
          (error) => {
              this.loading = false
              this.errorText = error.body.message || this.$t('Error eliminando sub-estado')
              return []
          })
        },
        itemProvider (tableComponentInfo) {
            this.loading = true
            this.errorText = ''
            return this.getAllStatus(tableComponentInfo).then(function (response) {
                let items = response.body
                this.totalRows = parseInt(response.headers.get('X-Total-Count'))
                this.loading = false
                return items
            },
            (error) => {
                this.loading = false
                this.errorText = error.body.message || this.$t('Error obteniendo el listado de estados')
                return []
            })
        }
    },
    mounted () {
      setInterval(() => {
        if (this.$refs.subStatusTable) {
          this.$refs.subStatusTable.refresh()
        }
      }, appConfig.SUBSTATUS_LIST_TTL)
    }
}