import appConfig from '@/config/applicationConfig.js'

export const CarrierServiceMixin = {
  data () {
    return {
      CARRIERS_ENDPOINT: `${appConfig.API_END_POINT}/carriers`,
      SHIPPING_GRATES_ENDPOINT: `${appConfig.API_END_POINT}/shippingrates`,
      HEADER: {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
    }
  },
  methods: {
    getCarriers (filter) {
      let endpoint = new URL(this.CARRIERS_ENDPOINT)
      if (filter) {
        Object.keys(filter).forEach(
          (key) => {
            if (filter[key] !== null) {
              endpoint.searchParams.append(key, filter[key])
            }
          }
        )
      }
      return this.$http.get(endpoint.href, this.HEADER)
    },
    getCarrier (id) {
      return this.$http.get(`${this.CARRIERS_ENDPOINT}/${id}`, this.HEADER)
    },
    createCarrier (carrier) {
      return this.$http.post(this.CARRIERS_ENDPOINT, carrier, this.HEADER)
    },
    updateCarrier (carrier) {
      return this.$http.put(`${this.CARRIERS_ENDPOINT}/${carrier.id}`, carrier, this.HEADER)
    },
    deleteCarrier (id) {
      return this.$http.delete(`${this.CARRIERS_ENDPOINT}/${id}`, this.HEADER)
    },
    getShippinGrates (tableInfo, filters) {
      let params = {}
      let url = new URL(this.SHIPPING_GRATES_ENDPOINT)

      params.from = tableInfo.currentPage - 1
      params.size = tableInfo.perPage

      if (tableInfo.sortBy) {
        params.sortBy = tableInfo.sortBy
        params.sortOrder = tableInfo.sortDesc ? 'desc' : 'asc'
      }

      params.zipCodes = filters.zipCode ? [filters.zipCode] : null
      params.weightStart = filters.weight
      params.weightEnd = filters.weight
      params.maxVolume = filters.maxVolume

      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )

      return this.$http.get(url.href, this.HEADER)
    },
    postShippingrates (shippingrateData) {
      return this.$http.post(this.SHIPPING_GRATES_ENDPOINT, shippingrateData, this.HEADER)
    },
    deleteShippingrates (shippingGrateId) {
      return this.$http.delete(`${this.SHIPPING_GRATES_ENDPOINT}/${shippingGrateId}`, this.HEADER)
    },
    addSellerToCarrier (carrierId, carrierseller) {
      return this.$http.post(`${this.CARRIERS_ENDPOINT}/${carrierId}/carrierseller`, carrierseller, this.HEADER)
    },
    getSellersFromCarrier (carrierId, tableFilter) {
      let url = new URL(`${this.CARRIERS_ENDPOINT}/${carrierId}/carrierseller`)
      let params = {}
      params.from = tableFilter.currentPage - 1
      params.size = tableFilter.perPage
      Object.keys(params).forEach(
        (key) => {
          if (params[key] !== null) {
            url.searchParams.append(key, params[key])
          }
        }
      )
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
      return this.$http.get(
        url.href,
        config
      )
    },
    delSellerToCarrier (carrierId, carriersellerId) {
      return this.$http.delete(`${this.CARRIERS_ENDPOINT}/${carrierId}/carrierseller/${carriersellerId}`, this.HEADER)
    },
    deleteMultipleShippingrates (ids) {
      let shippingrateData = {'delete': ids}
      return this.$http.post(`${this.SHIPPING_GRATES_ENDPOINT}/batch`, shippingrateData, this.HEADER)
    },
    requestGuide (idOrder) {
      return this.$http.get(`${this.CARRIERS_ENDPOINT}/order/${idOrder}/guide`, this.HEADER)
    }
  }
}

export const PickupPointServiceMixin = {
  data () {
    return {
      PICKUP_POINT_ENDPOINT: `${appConfig.API_END_POINT}/pickup-points`,
      HEADER: {
        headers: {
          Authorization: `Bearer ${this.$store.state.access_token}`
        }
      }
    }
  },
  methods: {
    getPickupPoints (filter) {
      let endpoint = new URL(this.PICKUP_POINT_ENDPOINT)
      if (filter) {
        Object.keys(filter).forEach(
          (key) => {
            if (filter[key] !== null) {
              endpoint.searchParams.append(key, filter[key])
            }
          }
        )
      }
      return this.$http.get(endpoint.href, this.HEADER)
    },
    searchPickupPoint (text) {
      let endpoint = new URL(this.PICKUP_POINT_ENDPOINT)
      if (text) endpoint.searchParams.append('text', text)
      return this.$http.get(endpoint.href, this.HEADER)
    },
    getPickupPoint (id) {
      return this.$http.get(`${this.PICKUP_POINT_ENDPOINT}/${id}`, this.HEADER)
    },
    createPickupPoint (pickupPoint) {
      return this.$http.post(this.PICKUP_POINT_ENDPOINT, pickupPoint, this.HEADER)
    },
    updatePickupPoint (pickupPoint) {
      return this.$http.put(`${this.PICKUP_POINT_ENDPOINT}/${pickupPoint.id}`, pickupPoint, this.HEADER)
    },
    deletePickupPoint (id) {
      return this.$http.delete(`${this.PICKUP_POINT_ENDPOINT}/${id}`, this.HEADER)
    }
  }
}
