<template>
  <b-container fluid class="animated fadeIn">
    <moon-loader :loading="loadingSave" class="mb-2" color="#0e67b3" size="30px"></moon-loader>
    <div v-show="errorText !== ''" class="alert alert-danger" role="alert" dismissible @dismised="errorText = ''">
      {{$t(errorText)}}
    </div>
    <div v-show="success" class="alert alert-success" role="alert" dismissible @dismised="success=false">
      {{$t('La acción fue completada con éxito')}}
    </div>
    <b-row>
      <b-col md="6" class="mb-3">
          <b-form-group id="nombre"
            horizontal
            :label-cols="4"
            breakpoint="md"
            class="mb-0"
            :label="$t('Buscar')"
            :invalid-feedback="!isValidText(filterRules.text, false)? $t('No es un valor válido') : ''"
            :state="isValidText(filterRules.text, false)"
            :description="$t('Este campo de filtro realizará la busqueda en los nombres de los sellers')"
            label-for="nameFormatter">
            <b-input-group>
              <b-form-input :state="isValidText(filterRules.text, false)" id="nameFormatter" v-model="filterRules.text" :placeholder="$t('Texto a buscar')" />
              <b-input-group-button>
                <b-btn :disabled="!isValidText(filterRules.text, false)" @click="filterTable">{{$t('Buscar')}}</b-btn>
              </b-input-group-button>
            </b-input-group>
          </b-form-group>
        </b-col>
      <b-col md="6">
        <button class="btn btn-primary float-right mr-2" @click="openOrganizationModal(selected)">{{$t('Crear seller')}}</button>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="col-md-auto">
        <b-pagination :total-rows="totalRows" :per-page="filterRules.size" v-model="filterRules.from" class="mb-3" />
      </b-col>
      <b-col class="col-md-auto per-page">
        <b-form-group id="perPage" horizontal :label="$t('Mostrar por página')">
          <b-form-select :options="pageOptions" v-model="filterRules.size" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="mt-1" align-self="start">
        <b>{{totalRows}}</b> {{$t('sellers encontrados')}}
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card header-bg-variant="primary" border-variant="primary" :header="$t('Lista de sellers')">
          <moon-loader :loading="loading" class="mb-2" color="#000" size="30px"></moon-loader>
          <b-table id="mainTable"
            ref="mainTable"
            show-empty
            striped
            hover
            foot-clone
            :sort-by.sync="filterRules.sortBy"
            :sort-desc.sync="filterRules.sortOrder"
            :per-page="filterRules.size"
            :current-page="filterRules.from"
            :items="itemProvider"
            :fields="fields"
            :filter="filter"
            :fixed=true
            responsive=true
            @row-clicked="openOrganizationModal"
            stacked="xl"
            :empty-text="$t('No hay sellers para mostrar')"
            :empty-filtered-text="$t('No hay sellers para mostrar')">
            <template slot="marketplace" slot-scope="row">
              <b-badge class="mr-1" pill
                v-for="site in row.item.marketplaces"
                :key="site.id"> {{site.name}} </b-badge>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      header-bg-variant="primary"
      ref="orgInfo"
      centered
      :title="selected.id ? $t('Guardar seller') : $t('Crear seller')"
      size="lg"
      @hidden="resetModal"
      @ok="handleSave"
      :cancel-title="$t('Cancelar')"
      :ok-title="selected.id ? $t('Guardar') : $t('Crear')"
      :ok-disabled="disableSave"
    >
      <b-container fluid>
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Nombre')"
              :invalid-feedback="!state.name? $t('El nombre es invalido') : ''"
              :state="state.name"
            >
              <b-form-input v-model="selected.name"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t(labelSellerAccount)"
              :invalid-feedback="!state.accountName? $t('La cuenta es invalida o ya existe') : ''"
              :state="state.accountName"
            >
              <b-form-input :disabled="Boolean(selected.id)"
                v-model="selected.accountName"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Razón social')"
              :invalid-feedback="!state.corporateName? $t('La razón social es inválida') : ''"
              :state="state.corporateName"
            >
              <b-form-input v-model="selected.corporateName"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('RFC')"
              :invalid-feedback="!state.rfc? $t('El RFC es inválido') : ''"
              :state="state.rfc"
            >
              <b-form-input v-model="selected.rfc"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Nombre Contacto')"
              :invalid-feedback="!state.contactPerson? $t('El nombre es inválido') : ''"
              :state="state.contactPerson"
            >
              <b-form-input v-model="selected.contactPerson"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Email Contacto')"
              :invalid-feedback="!state.email? $t('El email es inválido') : ''"
              :state="state.email"
            >
              <b-form-input v-model="selected.email"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Descripción')"
              :invalid-feedback="!state.description? $t('La descripción es invalida') : ''"
              :state="state.description"
            >
              <b-form-textarea
              :rows="3"
              :max-rows="6"
              v-model="selected.description"
              ></b-form-textarea>
            </b-form-group>
            <p class="counter">{{$t('Caracteres')}}: {{selected.description? selected.description.length : 0}} / 500</p>
          </b-col>
        </b-row>

        <!-- Comisiones -->
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Comisiones')"
              :invalid-feedback="!state.productCommission ? $t('Debe ser un valor entero o con decimales valido') : ''"
              :state="state.productCommission"
            >
              <b-form-group :description="$t('% de comisión por default')">
                <b-form-input
                  type="number"
                  :state="state.productCommission"
                  v-model="selected.productCommission"
                ></b-form-input>
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col md="10" offset-md="2">
            <b-card
              :header="$t('CSV de comisiones por categoria')"
              :sub-title="$t('Exportar e importar csv de comisiones')">
              <div v-show="errorModalText !== ''" fade class="alert alert-danger" role="alert" dismissible @dismised="errorModalText = ''">
                {{$t(errorModalText)}}
              </div>
              <b-row>
                <b-col md="3">
                  <button
                    v-on:click="exportProductCommission"
                    :disabled="!selected.id || loadingExport"
                    class="btn btn-primary float-left mt-3"
                    type="button"
                  >
                    <moon-loader :loading="loadingExport" class="mx-4 my-1" color="#FFF" size="30px"></moon-loader>
                    <span v-show="!loadingExport">{{$t('Exportar CSV')}}</span>
                  </button>
                </b-col>
                <b-col md="9">
                  <b-form-file
                    class="mt-3"
                    id="exportProductCommission"
                    ref="exportProductCommission"
                    :no-drop="true"
                    :disabled="!selected.id"
                    @click.native="$refs.exportProductCommission.reset()"
                    accept=".csv"
                    v-model="selected.productCommisionCSV"
                    :placeholder="$t('Importar CSV')">
                  </b-form-file>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <!-- Sitios -->
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Sitios')"
              :invalid-feedback="!Boolean(selected.sites) ? '' : $t('Debe seleccionar al menos 1 sitio')"
              :state="Boolean(state.sites)"
            >
              <b-row class="my-2">
                <b-col md="4">{{$t('Nombre')}}</b-col>
                <b-col md="8">{{$t('Política comercial')}}</b-col>
              </b-row>
              <b-row v-for="site in optionSites" :key="site.value">
                <b-col md="4">
                  <b-form-checkbox style="margin-top: 10px;" :id="site.value" v-model="selected.sites" :value="site.value" :disabled="isSiteDisabled(site)">{{site.text}}</b-form-checkbox>
                </b-col>
                <b-col md="8" class="col-multiselect">
                  <b-form-group>
                    <multiselect
                      :placeholder="$t('Seleccione una política comercial')"
                      :selectedLabel="$t('Seleccionado')"
                      :selectLabel="$t('Enter para agregar el tag')"
                      :deselectLabel="$t('Enter para retirar el tag')"
                      :options="site.tradeAgreements"
                      label="text"
                      track-by="id"
                      :multiple="true"
                      :taggable="true"
                      :disabled="disableTradeAgreements(site)"
                      :value="site.tradeAgreementsSelected"
                      @select="onSelectTradeAgreement($event, site)"
                      @remove="onRemoveTradeAgreement($event, site)">
                    </multiselect>
                  </b-form-group>
                  <div class="error-hint" v-show="!disableTradeAgreements(site) && (!site.tradeAgreementsSelected || site.tradeAgreementsSelected.length == 0)">
                    {{ $t('Debe seleccionar al menos 1 política comercial') }}
                  </div>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>

        <div class="border-y py-3 my-3">
          <b-row>
            <b-col md="12">
              <div class="d-flex">
                <b>{{$t('Dirección')}}</b> 
                <div class="ml-3 circle" @click="toggleAddress">
                  <span v-show="showAddress"><i class="fas fa-minus"></i></span>
                  <span v-show="!showAddress"><i class="fas fa-plus"></i></span>
                </div>
              </div>
            </b-col>
          </b-row>
          <transition name="organizationlist">
            <b-row class="pt-3 mt-3 mb-4" v-if="showAddress">
              <b-col md="12">
                <b-form-group
                  horizontal
                  :label-cols="2"
                  :label="$t('Calle')"
                  :invalid-feedback="!state.street? $t('La calle es inválida') : ''"
                  :state="state.street"
                >
                  <b-form-input v-model="selected.addressInfo.street"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  horizontal
                  :label-cols="2"
                  :label="$t('Número')"
                  :invalid-feedback="!state.number? $t('El número es inválido') : ''"
                  :state="state.number"
                >
                  <b-form-input v-model="selected.addressInfo.number"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  horizontal
                  :label-cols="2"
                  :label="$t('Ciudad / Municipio')"
                  :invalid-feedback="!state.city? $t('La ciudad / municipio es inválida') : ''"
                  :state="state.city"
                >
                  <b-form-input v-model="selected.addressInfo.city"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="neighborhood">
                <b-form-group
                  horizontal
                  :label-cols="2"
                  :label="$t('Colonia / Barrio')"
                  :invalid-feedback="!state.neighborhood? $t('La colonia / barrio es inválida') : ''"
                  :state="state.neighborhood"
                >
                  <b-form-input v-model="selected.addressInfo.neighborhood"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  horizontal
                  :label-cols="2"
                  :label="$t('Código postal')"
                  :invalid-feedback="!state.postalCode? $t('El código postal es inválido') : ''"
                  :state="state.postalCode"
                >
                  <b-form-input v-model="selected.addressInfo.postalCode"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  horizontal
                  :label-cols="2"
                  :label="$t('Estado_')"
                  :invalid-feedback="!state.state? $t('El estado es inválido') : ''"
                  :state="state.state"
                >
                  <b-form-input v-model="selected.addressInfo.state"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  horizontal
                  :label-cols="2"
                  :label="$t('Teléfono')"
                  :invalid-feedback="!state.phone? $t('El teléfono es inválido') : ''"
                  :state="state.phone"
                >
                  <b-form-input v-model="selected.addressInfo.phone"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  horizontal
                  :label-cols="2"
                  :label="$t('Extensión')"
                  :invalid-feedback="!state.ext? $t('El La extensión es inválida') : ''"
                  :state="state.ext"
                >
                  <b-form-input v-model="selected.addressInfo.ext"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  horizontal
                  :label-cols="2"
                  :label="$t('Celular')"
                  :invalid-feedback="!state.cellphone? $t('El celular es inválido') : ''"
                  :state="state.cellphone"
                >
                  <b-form-input v-model="selected.addressInfo.cellphone"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </transition>
        </div>
        <b-row>
          <b-col offset-md="2">
            <b-img id="logo" thumbnail rounded fluid class="m-3 float-right"
              v-if="Boolean(selected.imageFile)"
              :src="selected.imageFile"
              />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              id="imageToUpload"
              :label-cols="2"
              breakpoint="md"
              :label="!Boolean(selected.imageFile) ? $t('Logo') : $t('Cambiar Logo')">
              <b-form-file
                ref="fileinput"
                v-model="file"
                accept="image/*"
                :placeholder="$t('Seleccione una imagen...')"
                :class="$i18n.locale">
              </b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              horizontal
              :label-cols="2"
              :label="$t('Términos y legales')"
              :invalid-feedback="!state.terms? $t('La descripción es invalida') : ''"
              :state="state.terms"
            >
              <b-form-textarea
              :rows="3"
              :max-rows="6"
              v-model="selected.terms"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </b-container>
</template>
<script>
  import { OrganizationService } from '@/mixims/AdminServiceMixin'
  import { ValidationsMixim } from '@/mixims/ValidationsMixim'
  import { ProductServiceMixim } from '@/mixims/ProductServiceMixim'
  import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
  import appConfig from '@/config/applicationConfig.js'
  import { MarketplaceServiceMixim } from '@/mixims/MarketplaceServiceMixim.js'
  import Multiselect from 'vue-multiselect'

  export default {
    components: { MoonLoader, Multiselect },
    mixins: [OrganizationService, ValidationsMixim, MarketplaceServiceMixim, ProductServiceMixim],
    data: function () {
      return {
        success: null,
        errorText: '',
        errorModalText: '',
        loading: false,
        loadingSave: false,
        loadingExport: false,
        fields: [
          { key: 'accountName', label: this.$t('Cuenta Vtex'), 'class': 'text-left', sortable: true },
          { key: 'name', label: this.$t('Nombre'), 'class': 'text-left', sortable: true },
          { key: 'description', label: this.$t('Descripción'), 'class': 'text-left', sortable: true },
          { key: 'marketplace', label: this.$t('Sitios'), 'class': 'text-left', sortable: false }
        ],
        modal: {},
        selected: { 
          addressInfo: {}, 
          sites: []
        },
        allSellers: null,
        file: null,
        filterRules: {
          sortBy: 'accountName',
          sortOrder: true,
          size: 10,
          from: 1,
          text: null
        },
        totalRows: 0,
        pageOptions: [ 10, 15, 25, 50, 100 ],
        showAddress: false,
        optionSites: [], //this.$store.getters.marketplacesCombo
      }
    },
    methods: {
      isSiteDisabled (site) {
        return site.disabled === true
      },
      onSelectTradeAgreement (option, site) {
        if(!site)
          return

        const index = site?.tradeAgreementsSelected?.findIndex(x => x.id == option.id)
        if (index == undefined || index == -1) {
          if (!site.tradeAgreementsSelected || site.tradeAgreementsSelected.length == 0)
            site.tradeAgreementsSelected = [option]
          else 
            site.tradeAgreementsSelected.push(option)
        }
      },
      onRemoveTradeAgreement (option, site) {
        if(!site || option.disabled == true)
          return
        
        const index = site?.tradeAgreementsSelected?.findIndex(x => x.id == option.id)
        if (index >= 0 && !site.tradeAgreementsSelected[index].disabled)
          site.tradeAgreementsSelected.splice(index, 1)
      },
      filter () {
        return this.filterRules
      },
      exportProductCommission () {
        this.errorModalText = ''
        this.loadingExport = true
        this.getComissionsCSV(this.selected.accountName).then(
          (csvFile) => {
            this.loadingExport = false
            console.log(csvFile)
            let headers = csvFile.headers
            var blob = new Blob([csvFile.body], {
              type: { type: headers.map['content-type'] }
            })
            const blobURL = window.URL.createObjectURL(blob)
            const tempLink = document.createElement('a')
            tempLink.style.display = 'none'
            tempLink.href = blobURL
            tempLink.setAttribute('download', 'ProductCommissionCSV.csv')
            document.body.appendChild(tempLink)
            tempLink.click()
            document.body.removeChild(tempLink)
          },
          (error) => {
            this.loadingExport = false
            this.errorModalText = error.body && error.body.message ? error.body.message : 'Error obteniendo los datos de comisiones'
          }
        )
      },

      performSaveOrganization (toSave, imgData) {
        return this.saveOrganization(toSave).then(
          (res) => {
            if (imgData) {
              return this.uploadImage(res.body.id, imgData.img).then(
                () => {},
                (error) => {
                  this.errorText = error.body.message || 'Ocurrió un error el guardar la imagen del seller'
                }
              )
            }
          },
          (error) => {
            this.errorText = error.body.message || 'Ocurrió un error el guardar el seller'
          }
        )
      },

      handleSave () {
        this.loadingSave = true
        let toSave = {...this.selected}
        let imgData = this.file ? {img: this.file} : null
        toSave.marketplaces = []

        this.optionSites.forEach(item => {
          let isSelectedMarketplace = toSave.sites.some(site => { return site == item.value })
          if (isSelectedMarketplace) {
            toSave.marketplaces.push({
              'id': item.value,
              'name': item.text,
              'accountName': item.value,
              'tradeAgreements': item.tradeAgreementsSelected?.map(x=> ({
                "id": x.id,
                "name": x.text,
                "isSelected": true
              }))
            })
          }
        })

        this.performSaveOrganization(toSave, imgData).then(
          () => {
            if (toSave.productCommisionCSV) {
              let formData = new FormData()
              formData.append('file', toSave.productCommisionCSV)
              this.postComissionsCsvFile(formData, ';', toSave.accountName).then(
                () => {
                  this.$refs.mainTable.refresh()
                  this.loadingSave = false
                  this.success = true
                },
                (error) => {
                  this.errorText = error.body.message || 'Ocurrió un error al importar el archivo CSV'
                  this.loadingSave = false
                }
              )
            } else {
              this.resetModal()
              this.loadingSave = false
              this.success = true
              this.$refs.mainTable.refresh()
            }
          },
          (error) => {
            this.errorText = error.body.message || 'Ocurrió un error el guardar el seller'
          }
        )
      },
      openOrganizationModal (org) {
        this.loading = true
        let _this = this
        this.errorText = ''
        this.success = false
        this.showAddress = false
        this.selected.sites = []
        org.sites = this.selected.sites

        this.getAllOrganizations().then(
          (sellers) => {
            _this.loading = false
            _this.allSellers = sellers
            _this.selected = {...org}
            _this.optionSites?.forEach(x => {
              x['disabled'] = false
              x.tradeAgreementsSelected = []
            })

            if (org.marketplaces) {
              for (let i = 0; i < org.marketplaces.length; i++) {
                this.$set(this.selected, 'sites', [...this.selected.sites, org.marketplaces[i].id])
                _this.optionSites?.forEach(x => {
                  if (x.value === org.marketplaces[i].id) {
                    x['disabled'] = true
                    const array = org?.marketplaces[i]?.tradeAgreements
                      ?.filter(x=> x.isSelected == true)
                      ?.map(ta=>({
                        'id': ta.id,
                        'text': ta.name,
                        'disabled': true
                      }))
                    x.tradeAgreementsSelected = array && array.length > 0 ? array : []
                  }
                })
              }
            }

            if (!_this.selected.addressInfo) _this.selected.addressInfo = {}
            _this.$refs.orgInfo.show()
          },
          () => {
            this.errorText = 'Error obteniendo el listado actualizado de Sellers'
          }
        )
      },
      resetModal () {
        this.showAddress = false
        this.selected = { 
          addressInfo: {},
          sites: [] 
        }
        this.optionSites?.forEach(x => {
            x.tradeAgreementsSelected = []
        })
        this.file = null
        this.allSellers = null
        this.$refs.fileinput.reset()
        this.errorModalText = ''
        // this.$refs.mainTable.refresh()
      },
      filterTable () {
        this.$refs.mainTable.refresh()
      },
      itemProvider (ctx) {
        this.loading = true
        let filter = ctx.filter()
        return this.getOrganizations(filter).then(
          response => {
            this.totalRows = parseInt(response.headers.get('X-Total-Count'))
            this.loading = false
            return response.body
          },
          error => {
            this.errorText = error.body.message || 'Error obteniendo el listado de sellers'
            this.loading = false
            return []
          }
        )
      },
      uploadImage: function (id, img) {
        let formData = new FormData()
        let endPoint = `${appConfig.API_END_POINT}/admin/organization/${id}/image`
        formData.append('file', img)
        return this.$http.post(endPoint, formData, {
          headers: {
            Authorization: `Bearer ${this.$store.state.access_token}`
          }
        }).catch(() => {
          this.errorText = 'Ocurrio un error al cargar la imagen'
        })
      },
      existsSeller: function (seller) {
        return this.allSellers.find(itemSeller => itemSeller.accountName === seller)
      },
      toggleAddress () {
        this.showAddress = !this.showAddress
      },
      accountNameMaxChars () {
        return parseInt(process.env.VUE_APP_VTEX_ACCOUNT_MAX_CHARS)
      },
      disableTradeAgreements (site) {
        const index = this.selected?.sites?.findIndex(x=> x == site.value)
        return index == undefined || index == -1
      }
    },
    computed: {
      disableSave () {
        var marketplaces = []
        this.optionSites.forEach(item => {
          let isSelectedMarketplace = this.selected.sites.some(site => { return site == item.value })
          if (isSelectedMarketplace) {
            marketplaces.push({
              'id': item.value,
              'tradeAgreements': item.tradeAgreementsSelected
            })
          }
        })
        if(!marketplaces || marketplaces.length == 0 || marketplaces.filter(x => x.tradeAgreements && x.tradeAgreements.length > 0).length != marketplaces.length) 
         return true;
        
        return Object.values(this.state).includes(false) || this.loading
      },
      state () {
        return {
          name: this.isValidText(this.selected.name, true, 41),
          accountName: Boolean(this.selected.id) || (this.isValidTextOneWord(this.selected.accountName, true, this.accountNameMaxChars()) && !this.existsSeller(this.selected.accountName)),
          corporateName: this.isValidText(this.selected.corporateName, true, 41),
          rfc: this.isValidText(this.selected.rfc, true, 13),
          email: this.validateEmail(this.selected.email, true),
          contactPerson: this.isValidText(this.selected.contactPerson, true, 80),
          description: this.isValidTextArea(this.selected.description, true, 500),
          terms: this.isValidTextArea(this.selected.terms, true),
          street: this.isValidText(this.selected.addressInfo && this.selected.addressInfo.street ? this.selected.addressInfo.street : '', false),
          number: this.isValidInteger(this.selected.addressInfo && this.selected.addressInfo.number ? this.selected.addressInfo.number : '', false),
          city: this.isValidText(this.selected.addressInfo && this.selected.addressInfo.city ? this.selected.addressInfo.city : '', false),
          state: this.isValidText(this.selected.addressInfo && this.selected.addressInfo.state ? this.selected.addressInfo.state : '', false),
          postalCode: this.isValidCP(this.selected.addressInfo && this.selected.addressInfo.postalCode ? this.selected.addressInfo.postalCode : '', false),
          phone: this.isValidText(this.selected.addressInfo && this.selected.addressInfo.phone ? this.selected.addressInfo.phone : '', false),
          ext: this.isValidText(this.selected.addressInfo && this.selected.addressInfo.ext ? this.selected.addressInfo.ext : '', false),
          cellphone: this.isValidText(this.selected.addressInfo && this.selected.addressInfo.cellphone ? this.selected.addressInfo.cellphone : '', false),
          neighborhood: this.isValidText(this.selected.addressInfo && this.selected.addressInfo.neighborhood ? this.selected.addressInfo.neighborhood : '', false),
          sites: Boolean(this.selected.sites) && this.selected.sites.length > 0,
          productCommission: this.isValidFloat(this.selected.productCommission, false)
        }
      },
      labelSellerAccount () {
        return process.env.VUE_APP_SELLER_ACCOUNT_LABEL
      },
      neighborhood () {
        return appConfig.NEIGHBORHOOD
      }
    },
    mounted () {
      this.optionSites = []
      this.getMarketplaces(0, 100).then(
        (res) => {
          for (let i = 0; i < res.body.length; i++) {
            this.optionSites.push({
              'value': res.body[i].id,
              'text': res.body[i].name,
              'disabled': false,
              'tradeAgreements': res?.body[i]?.tradeAgreements?.map(ta=>({
                'id': ta.id,
                'text': ta.name,
              })),
              'tradeAgreementsSelected': []
            })
          }
        },
        (error) => {
          this.errorText = error.body.message || 'Ocurrió un error al obtener Sitios'
        }
      )
    }
  }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .overflow-ellipsis {
    text-overflow: ellipsis;
  }
  #logo {
    width: 75px!important;
    height: 75px!important;
  }
  .counter {
    float: right;
    font-size: 10px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid grey;
    background: whitesmoke;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circle:hover {
    opacity: 0.6;
  }
  .organizationlist-enter-active, .organizationlist-leave-active {
    transition: all .5s;
  }

  .organizationlist-enter, .organizationlist-leave-to {
    margin: 0;
    padding: 0;
    opacity: 0;
  }
  .border-y {
    border-top: 1px solid #e3e8ec!important;
    border-bottom: 1px solid #e3e8ec!important;
  }
  .custom-control-description {
    word-break: break-word;
  }
  .error-hint {
    margin-top: -15px;
    margin-bottom: 12px;
    font-size: .8rem;
    color: #f86c6b;
  }
</style>
